import { createReducer, on } from '@ngrx/store';
import { IDeviceStatisticsInformationEventDetail, ITreeModel, DeviceMetadataModel } from 'src/app/core/models/device-statistics.model';
import { DeviceStatisticsApiActions } from '../../actions/device-statistics-page-actions';
import { IChartInputData } from 'src/app/core/models/chart.model';

import { format, startOfMonth } from 'date-fns';
export const deviceStatisticsFeatureKey = 'configurationApi';

export interface DeviceStatisticsState {
    FTAE: ITreeModel[];
    PIAF: ITreeModel[];
    loadingFTAETree: boolean,
    loadingPIAFTree: boolean,
    selectedDevice: ITreeModel | null;
    error: any;
    showDrawer: boolean;
    source_type: "FTAE" | "PIAF";
    deviceDashboard: {
        loading: boolean;
        data: any
    };
    deviceControllerAlarmsChart: {
        loading: boolean;
        items: any;
        showPriorityChart: boolean;
    };
    deviceTable: {
        loading: boolean;
        data: {
            items: any[],
            nextCursor: string,
            isAgridShow: boolean
        }

    },
    alarmsTabData: {
        alarmsTable: {
            data: {
                items: any[],
                nextCursor: string,
                isAgridShow: boolean,
            }
        },
        tableLoading: boolean
        chartLoading: boolean
        alarmsChart: any
        lastUpdated: string | undefined
        showPriorityChart: boolean
    }
    deviceSummary: {
        graphData: IChartInputData[],
        showChart: boolean,
        alarmData: {
            activeCount: number,
            chatteringCount: number,
            fleetingCount: number,
            floodingCount: number,
            staleCount: number
        },
        alarmType: "PAI" | "PAO",
        deviceStatus: boolean
    },
    deviceInformationHead: {
        // loading: boolean;
        logsCount: number,
        objectPath: string,
    };
    deviceInformation: {
        deviceInformationLoading: boolean
        items: {
            items: any[],
            nextCursor: string
        },
    },
    deviceSummaryConfigError: boolean,
    deviceSummaryLoading: boolean,
    selectedInfo: IDeviceStatisticsInformationEventDetail,
}

export const initialState: DeviceStatisticsState = {
    FTAE: [],
    PIAF: [],
    loadingFTAETree: false,
    loadingPIAFTree: false,
    selectedDevice: null,
    error: {},
    source_type: "FTAE",
    showDrawer: true,
    deviceDashboard: {
        loading: false,
        data: undefined
    },
    deviceControllerAlarmsChart: {
        loading: false,
        items: [],
        showPriorityChart: false
    },
    deviceTable: {
        loading: false,
        data: {
            items: [],
            nextCursor: "",
            isAgridShow: false
        }
    },
    alarmsTabData: {
        alarmsTable: {
            data: {
                items: [],
                nextCursor: "",
                isAgridShow: false
            }
        },
        tableLoading: false,
        chartLoading: false,
        alarmsChart: [],
        lastUpdated: undefined,
        showPriorityChart: false
    },
    deviceInformation: {
        deviceInformationLoading: false,
        items: {
            items: [],
            nextCursor: ""
        },
    },
    deviceSummary: {
        graphData: [
        ],
        showChart: false,
        alarmData: {
            activeCount: 8,
            chatteringCount: 12,
            fleetingCount: 14,
            floodingCount: 9,
            staleCount: 6
        },
        alarmType: "PAI",
        deviceStatus: true
    },
    deviceInformationHead: {
        logsCount: 0,
        objectPath: "",
    },
    deviceSummaryConfigError: false,
    deviceSummaryLoading: false,
    selectedInfo: {
        catalogNumber: '',
        diagnosticLogs: 0,
        hardwareDevice: '',
        productName: '',
        productType: '',
        status: '',
        majorRevision: '',
        engineeringUnit: '',
        maximum: '',
        minimum: '',
        minorRevision: '',
        vendor: '',
    },
};

export const reducer = createReducer(
    initialState,
    on(DeviceStatisticsApiActions.loadFtae, (state) => {
        return {
            ...state,
            loadingFTAETree: true,
            FTAE: []
        }
    }),
    on(DeviceStatisticsApiActions.loadFtaeSuccess, (state, action) => {
        return {
            ...state,
            FTAE: [...action.data],
            loadingFTAETree: false
        }
    }),
    on(DeviceStatisticsApiActions.loadFtaeFailure, (state, action) => {
        return {
            ...state,
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadPiaf, (state) => {
        return {
            ...state,
            loadingPIAFTree: true,
            PIAF: []
        }
    }),
    on(DeviceStatisticsApiActions.loadPiafSuccess, (state, action) => {
        return {
            ...state,
            PIAF: [...action.data],
            loadingPIAFTree: false
        }
    }),
    on(DeviceStatisticsApiActions.loadPiafFailure, (state, action) => {
        return {
            ...state,
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.setSelectedDevice, (state, action) => {
        if(action.item) {
            return {
                ...state,
                selectedDevice: { ...action.item }
            }
        } else {
            return {
                ...state,
                selectedDevice: null
            }
        }
    }),
    on(DeviceStatisticsApiActions.toggleDrawer, (state, action) => {
        return {
            ...state,
            showDrawer: action.toggleState,
        }
    }),
    
    on(DeviceStatisticsApiActions.clearDeviceSummaryError, (state) => {
        return {
            ...state,
            deviceSummaryConfigError: false
        }
    }),
    on(DeviceStatisticsApiActions.toggleSource, (state, action) => {
        return {
            ...state,
            source_type: action.source_type
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesDashboard, (state) => {
        return {
            ...state,
            deviceDashboard: {
                data: undefined,
                loading: true
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesSuccess, (state, action) => {
        return {
            ...state,
            deviceDashboard: {
                data: action.data,
                loading: false
            },
        deviceSummaryConfigError : action?.data?.alarmStatus?.showAlarmStatus ? state.deviceSummaryConfigError : true
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesFailure, (state, action) => {
        return {
            ...state,
            deviceDashboard: {
                data: undefined,
                loading: false
            },
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChart, (state) => {
        return {
            ...state,
            deviceControllerAlarmsChart: {
                items: undefined,
                loading: true,
                showPriorityChart: false
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChartSuccess, (state, action) => {
        return {
            ...state,
            deviceControllerAlarmsChart: {
                items: action.items,
                loading: false,
                showPriorityChart: action.showPriorityChart
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChartFailure, (state, action) => {
        return {
            ...state,
            deviceControllerAlarmsChart: {
                items: undefined,
                loading: false,
                showPriorityChart: false
            },
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesTable, (state, action) => {
        if (action.reload) {
            return {
                ...state,
                deviceTable: {
                    data: {
                        items: [],
                        nextCursor: "",
                        isAgridShow: false
                    },
                    loading: false
                }
            }
        } else {
            return {
                ...state
            }
        }
    }),
    on(DeviceStatisticsApiActions.setEmptyDevicesTable, (state, action) => {
        return {
            ...state,
            deviceTable: {
                data: {
                    items: [],
                    nextCursor: "",
                    isAgridShow: false
                },
                loading: false
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesTableSuccess, (state, action) => {
        try {
            return {
                ...state,
                deviceTable: {
                    data: {
                        items: [...state.deviceTable.data.items, ...action.items],
                        nextCursor: action.cursor,
                        isAgridShow: action.isAgridShow
                    },
                    loading: false
                }
            }
        } catch (e) {
            console.error(e);
            return {
                ...state,
                deviceTable: {
                    data: {
                        ...state.deviceTable.data,
                        items: [...state.deviceTable.data.items],
                        isAgridShow: action.isAgridShow
                    },
                    loading: false
                }
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesTableFailure, (state, action) => {
        return {
            ...state,
            deviceDashboard: {
                data: {
                    items: [],
                    nextCursor: "",
                    isAgridShow: false
                },
                loading: false
            },
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadAlarmsTabTable, (state, action) => {
        if (action.reload) {
            return {
                ...state,
                alarmsTabData: {
                    ...state.alarmsTabData,
                    alarmsTable: {
                        data: {
                            items: [],
                            nextCursor: "",
                            isAgridShow: false
                        }
                    },
                    tableLoading: true
                }
            }
        } else {
            return {
                ...state
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadAlarmsTabSuccess, (state, action) => {
        return {
            ...state,
            alarmsTabData: {
                ...state.alarmsTabData,
                alarmsTable: {
                    data: {
                        items: [...state.alarmsTabData.alarmsTable.data.items, ...action.aGridData],
                        nextCursor: action.nextCursor,
                        isAgridShow: action.isAgridShow
                    },
                    tableLoading: false
                },
                lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadAlarmsTabFailure, (state, action) => {
        return {
            ...state,
            alarmsTabData: {
                ...state.alarmsTabData,
                alarmsTable: {
                    data: {
                        items: [],
                        nextCursor: "",
                        isAgridShow: false
                    },
                    tableLoading: false
                },
                lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
            },
            error: action.error,
        }
    }),
    
    on(DeviceStatisticsApiActions.loadDeviceAlarmsChart, (state) => {
        return {
            ...state,
            alarmsTabData: {
                ...state.alarmsTabData,
                alarmsChart: [],
                chartLoading: true,
                showPriorityChart: false
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDeviceAlarmsChartSuccess, (state, action) => {
        try {
            return {
                ...state,
                alarmsTabData: {
                    ...state.alarmsTabData,
                    alarmsChart: [...action.items],
                    chartLoading: false,
                    showPriorityChart: action.showPriorityChart
                }
            }
        } catch (e) {
            console.error(e);
            return {
                ...state,
                alarmsTabData: {
                    ...state.alarmsTabData,
                    chartLoading: false
                }
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDeviceAlarmsChartFailure, (state, action) => {
        return {
            ...state,
            alarmsTabData: {
                ...state.alarmsTabData,
                alarmsChart: [],
                chartLoading: false
            },
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesSummary, (state) => {
        return {
            ...state,
            deviceSummaryLoading: true
        };
    }),
    on(DeviceStatisticsApiActions.loadDevicesSummarySuccess, (state, action) => {
        return {
            ...state,
            deviceSummary: action.data,
            deviceSummaryLoading: false,
            deviceSummaryConfigError : action?.data?.showChart ? state.deviceSummaryConfigError: true
         }
    }),
    on(DeviceStatisticsApiActions.loadDevicesSummaryFailure, (state, action) => {
        return {
            ...state,
            error: action.error,
            deviceSummaryLoading: false
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformationHead, (state) => {
        return {
            ...state,
        };
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformationHeadSuccess, (state, action) => {
        return {
            ...state,
            deviceInformationHead: {
                logsCount: action.logsCount,
                objectPath: action.objectPath
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformationHeadFailure, (state, action) => {
        return {
            ...state,
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformation, (state, action) => {
        if (action.reload) {
            return {
                ...state,
                deviceInformation: {
                    items: {
                        items: [],
                        nextCursor: ""
                    },
                    deviceInformationLoading: true
                }
            }
        } else {
            return {
                ...state
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformationSuccess, (state, action) => {
        return {
            ...state,
            deviceInformation: {
                items: {
                    items: [...state.deviceInformation.items.items, ...action.items],
                    nextCursor: action.nextCursor
                },
                deviceInformationLoading: false
            }
        }
    }),
    on(DeviceStatisticsApiActions.loadDevicesInformationFailure, (state, action) => {
        return {
            ...state,
            deviceInformation: {
                items: {
                    items: [],
                    nextCursor: ""
                },
                deviceInformationLoading: false
            },
            error: action.error,
        }
    }),
    on(DeviceStatisticsApiActions.setSelectedDeviceInformationEvent, (state, action) => {
        return {
            ...state,
            selectedDeviceInfoEvent: action.selectedDeviceInfoEvent,
        }
    }),
);

